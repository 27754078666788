import { CommonModule, Location } from "@angular/common";
import { Component, inject, Input, OnChanges, SimpleChanges } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

import { User } from "@models/models";
import { NgIconComponent, provideIcons } from "@ng-icons/core";
import { iconoirNavArrowLeft } from "@ng-icons/iconoir";
import { TranslateModule } from "@ngx-translate/core";
import { HistoryService } from "@services/history.service";
import { SectorService } from "@services/sector.service";
import { TranslationService } from "@services/translation.service";
import { WidthService } from "@services/width.service";
import { WorkspaceService } from "@services/workspace.service";
import { ButtonModule } from "primeng/button";
import { SkeletonModule } from "primeng/skeleton";
import { BehaviorSubject } from "rxjs";

@Component({
  selector: "app-main-title",
  standalone: true,
  imports: [CommonModule, ButtonModule, TranslateModule, NgIconComponent, SkeletonModule],
  providers: [provideIcons({ iconoirNavArrowLeft })],
  templateUrl: "./main-title.component.html",
})
export class MainTitleComponent implements OnChanges {
  @Input() user?: User;
  @Input() url: string | null = "";
  @Input() secondScreen = false;
  @Input() titleClass = "";

  private readonly translationService = inject(TranslationService);
  private readonly location = inject(Location);
  private readonly workspaceService = inject(WorkspaceService);
  private readonly sectorService = inject(SectorService);

  readonly historyService = inject(HistoryService);
  readonly widthService = inject(WidthService);

  mainTitle = "";
  translationLoading = false;
  translations$ = new BehaviorSubject<any>({});
  isBack = false;

  multipleWorkspace = false;
  inEducation = false;
  backIndex = -1;

  constructor() {
    this.translationLoading = true;
    this.translationService
      .get("page.app.sidebar")
      .pipe(takeUntilDestroyed())
      .subscribe({
        error: () => {
          this.translationLoading = false;
        },
        next: val => {
          this.translationLoading = false;
          this.translations$.next(val);
          if (this.url) {
            this.getTitle(this.url);
          }
        },
      });
    this.workspaceService.workspaces.pipe(takeUntilDestroyed()).subscribe(val => {
      if (val?.length > 1) this.multipleWorkspace = true;
      else this.multipleWorkspace = false;
    });
    this.sectorService.education
      .pipe(takeUntilDestroyed())
      .subscribe(val => (this.inEducation = val));
    this.historyService.currentIndexListener
      .pipe(takeUntilDestroyed())
      .subscribe(val => (this.backIndex = val));
  }

  ngOnChanges(changes: SimpleChanges): void {
    const url = changes["url"]?.currentValue;
    if (url) this.getTitle(url);
  }

  getTitle(url: string) {
    this.isBack = false;
    if (!this.secondScreen && url.split("/").length > 2) {
      this.isBack = true;
    }
    if (url.startsWith("/content")) {
      this.mainTitle = this.translations$.value["content"];
      if (url.split("/").length === 3 && url.split("/")[2] === "create") this.isBack = true;
    } else if (url.startsWith("/company")) this.mainTitle = this.translations$.value["company"];
    else if (url.startsWith("/collaborators"))
      this.mainTitle = this.translations$.value["collaborators"];
    else if (url.startsWith("/settings")) this.mainTitle = this.translations$.value["settings"];
    else if (url.startsWith("/task")) {
      // this.isBack = true;
      this.mainTitle = this.translations$.value["tasks"];
      if (url.split("/").length > 3) this.isBack = true;
    } else if (url.startsWith("/workspace")) {
      this.mainTitle = this.translations$.value["workspace"];
      if ((url.split("/").length === 3 || url.split("/").length === 4) && !this.multipleWorkspace)
        if (!this.secondScreen) this.isBack = false;
    } else if (url.startsWith("/messages")) {
      if (!this.secondScreen) this.isBack = false;
      this.mainTitle = this.translations$.value["messages"];
    } else if (url.startsWith("/opportunities")) {
      if (url === "/opportunities") this.mainTitle = this.translations$.value["opportunities"];
      else if (url.split("/").length === 3) {
        if (url === "/opportunities/split") {
          this.mainTitle = this.translations$.value["opportunities"];
        } else if (this.inEducation) this.mainTitle = this.translations$.value["student-list"];
        else this.mainTitle = this.translations$.value["applicant-list"];
      } else if (url.split("/").length === 4) {
        if (url.split("/")[3] === "create") {
          this.isBack = true;
          if (url.split("/")[2] === "job") {
            this.mainTitle = this.translations$.value["job-opportunity"];
          } else if (url.split("/")[2] === "exchange") {
            this.mainTitle = this.translations$.value["exchange-opportunity"];
          } else if (url.split("/")[2] === "service") {
            this.mainTitle = this.translations$.value["service-opportunity"];
          } else if (url.split("/")[2] === "event") {
            this.mainTitle = this.translations$.value["event-opportunity"];
          } else {
            this.mainTitle = this.translations$.value["opportunities"];
          }
        } else if (url.split("/")[2] === "detail") {
          this.isBack = true;
          this.mainTitle = this.translations$.value["opportunityDetail"];
        } else {
          this.isBack = true;
          if (this.inEducation) this.mainTitle = this.translations$.value["studentDetail"];
          else this.mainTitle = this.translations$.value["applicantDetail"];
        }
      } else {
        this.isBack = true;
        this.mainTitle = this.translations$.value["task"];
      }
    } else if (url.startsWith("/profile")) this.mainTitle = this.translations$.value["profile"];
    else if (url.startsWith("/user-list")) {
      if (url.includes("group"))
        if (this.inEducation) this.mainTitle = this.translations$.value["student-list-group"];
        else this.mainTitle = this.translations$.value["user-list-group"];
      else if (url.includes("filter"))
        this.mainTitle = this.translations$.value["advanced-user-search"];
      else {
        if (url.split("/").length === 4) {
          if (this.inEducation) this.mainTitle = this.translations$.value["student-single"];
          else this.mainTitle = this.translations$.value["user-single"];
        } else {
          if (this.inEducation) this.mainTitle = this.translations$.value["student-list"];
          else this.mainTitle = this.translations$.value["user-list"];
        }
      }
    } else if (url.startsWith("/forum")) {
      this.mainTitle = this.translations$.value["forum"];
    } else if (url.startsWith("/roadmap")) {
      this.mainTitle = this.translations$.value["roadmap"];
    } else if (url.startsWith("/community")) {
      this.mainTitle = this.translations$.value["forum"];
    } else if (url.startsWith("/questionnaire")) {
      this.mainTitle = this.translations$.value["questionnaire"];
    } else if (url === "/" || url.startsWith("/?"))
      this.mainTitle = this.translations$.value["home"];
  }

  back() {
    if (this.secondScreen) {
      this.historyService.goBack();
    } else {
      this.location.back();
    }
  }
}
