import { inject, Injectable } from "@angular/core";

import { BehaviorSubject } from "rxjs";

import { SplitService } from "./split.service";

interface HistoryState {
  url: string;
  state: any;
}
@Injectable({
  providedIn: "root",
})
export class HistoryService {
  private readonly splitService = inject(SplitService);

  private historyStack: HistoryState[] = [];

  private currentIndex = new BehaviorSubject<number>(-1);
  currentIndexListener = this.currentIndex.asObservable();

  pushState(url: string, state: any, noNavigation?: boolean) {
    this.historyStack = this.historyStack.slice(0, this.currentIndex.value + 1);
    if (
      this.historyStack.length === 0 ||
      (this.historyStack.length > 0 &&
        this.historyStack?.[this.historyStack.length - 1]?.url !== url)
    ) {
      this.historyStack.push({ url, state });
      this.currentIndex.next(this.currentIndex.value + 1);
      if (!noNavigation) this.splitService.navigate(true, url.split("/"));
    }
  }

  goBack() {
    if (this.currentIndex.value > 0) {
      this.currentIndex.next(this.currentIndex.value - 1);
      const previousState = this.historyStack[this.currentIndex.value];
      this.splitService.navigate(true, previousState.url.split("/"));
    }
  }

  goForward(): boolean {
    if (this.currentIndex.value < this.historyStack.length - 1) {
      this.currentIndex.next(this.currentIndex.value + 1);
      const nextState = this.historyStack[this.currentIndex.value];
      this.splitService.navigate(true, nextState.url.split("/"));
      return true;
    }
    return false;
  }

  clear() {
    this.currentIndex.next(-1);
    this.historyStack = [];
  }

  initializeCurrentState(
    url: string,
    state: any,
    noNavigation?: boolean,
    reset?: HistoryState | boolean
  ) {
    if (typeof reset === "boolean") {
      this.currentIndex.next(-1);
      this.historyStack = [];
    } else if (reset) {
      this.currentIndex.next(0);
      this.historyStack = [reset];
    }
    this.pushState(url, state, noNavigation);
  }
}
