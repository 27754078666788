<div class="flex flex-row items-center gap-4">
  <div class="w-max @sm:w-10">
    <p-button
      *ngIf="(!secondScreen && isBack) || (secondScreen && (backIndex ?? 0) > 0)"
      styleClass="p-button-text p-button-rounded p-button-secondary small-button @lg:normal-button"
      icon="pi"
      (onClick)="back()"
    >
      <ng-icon class="text-2xl" strokeWidth="2" name="iconoirNavArrowLeft" />
    </p-button>
  </div>
  <div *ngIf="translationLoading" class="my-0 h-6 w-64 @sm:w-64 @md:w-96 @lg:h-9">
    <p-skeleton height="100%" width="100%" />
  </div>
  <h1
    *ngIf="!translationLoading"
    class="my-0 text-2xl font-bold"
    [ngClass]="titleClass"
    [ngStyle]="{ 'line-height': mainTitle === 'home' ? '2.25rem !important' : '3rem !important' }"
  >
    {{ mainTitle }}
  </h1>
</div>
