import { Location } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { DestroyRef, inject, Injectable } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

import { CustomSocket } from "@common/custom-socket";
import {
  BackendResponse,
  Chat,
  ChatMember,
  ChatRoom,
  MetaCountBackendResponse,
  User,
} from "@models/models";
import { environment } from "@src/environments/environment";
import { BehaviorSubject, delay, first, forkJoin, map, of, switchMap } from "rxjs";

import { AuthService } from "./auth.service";
import { LocalStorageService } from "./local-storage.service";

interface UserChat {
  userId?: number;
  roomId: number;
  lastRead?: number;
  lastMessage?: Chat;
}

@Injectable({
  providedIn: "root",
})
export class MessageService {
  private readonly socket = inject(CustomSocket);
  private readonly destroyRef = inject(DestroyRef);
  private readonly httpService = inject(HttpClient);
  private readonly authService = inject(AuthService);
  private readonly localStorageService = inject(LocalStorageService);
  private readonly locationService = inject(Location);

  private readonly url = environment.backendUrl;

  total = 0;
  pageNum = 1;
  pageSize = 0;

  messageSession = true;
  lastSessionId = 0;

  readonly loading = new BehaviorSubject<boolean>(false);
  readonly userIdMessages = new BehaviorSubject<UserChat[]>([]);
  readonly chatRooms = new BehaviorSubject<ChatRoom[]>([]);

  priorUser?: any;
  user?: User;

  constructor() {
    this.authService.user.pipe(takeUntilDestroyed()).subscribe(val => {
      this.priorUser = { ...(this.user ?? {}) };
      this.user = val;
      if (this.user?.id !== this.priorUser?.id) {
        this.loading.next(true);
        this.pageNum = 1;
        this.getChatRooms(this.pageNum, this.pageSize)
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe({
            error: () => this.loading.next(false),
            next: val => {
              this.loading.next(false);
              if (val.message === "success") {
                this.total = val.result.meta.totalItems;
                this.chatRooms.next([
                  ...(val.result.items ?? []).map(room => {
                    return {
                      ...(room ?? {}),
                      unread:
                        (this.userIdMessages.value ?? []).find((a: any) => a.roomId === room.id)
                          ?.lastRead !== room.lastMessage?.id,
                    };
                  }),
                ]);
              }
            },
          });
      }
    });
    const priorMessage = JSON.parse(this.localStorageService.getData("messages") ?? "[]");
    if (priorMessage) {
      if (Array.isArray(priorMessage)) this.userIdMessages.next(priorMessage);
      else {
        this.localStorageService.removeData("messages");
        this.localStorageService.saveData("messages", "[]");
        this.userIdMessages.next([]);
      }
    }
    this.userIdMessages
      .pipe(takeUntilDestroyed())
      .subscribe(val => this.localStorageService.saveData("messages", JSON.stringify(val)));
    // this.chatRooms.pipe(takeUntilDestroyed()).subscribe(val => {
    // });
  }

  getChats(chatRoomId: number | null) {
    return this.chatRooms.value.find(a => a.id === chatRoomId)?.chats ?? [];
  }

  changeChatRoom({
    type,
    data,
  }:
    | { type: "add"; data: ChatRoom }
    | { type: "read"; data: { id: number; lastMessage: Chat; members?: ChatMember[] } }
    | {
        type: "change";
        data: { id: number; name?: string; lastMessage?: Chat; lastRead?: number };
      }) {
    if (type === "add") {
      this.total++;
      this.chatRooms.next([data, ...(this.chatRooms.value ?? [])]);
    } else if (type === "change") {
      if (this.chatRooms.value.find(({ id }) => id === data.id)) {
        this.chatRooms.next(
          (this.chatRooms.value ?? []).map(room => {
            if (room.id === data.id) {
              return {
                ...(room ?? {}),
                ...(data ?? {}),
              };
            }
            return room;
          })
        );
        if (data.lastMessage && data.lastRead)
          this.userIdMessages.next(
            this.userIdMessages.value.map(a => {
              if (a.roomId !== data.id) return a;
              return {
                ...(a ?? {}),
                lastMessage: data.lastMessage,
                lastRead: data.lastRead,
              };
            })
          );
      }
    } else if (type === "read") {
      const messages = this.userIdMessages.value;
      let newMessages = [];
      if (messages.find((a: any) => a.roomId === data.id)) {
        newMessages = messages.map((message: any) => {
          if (message.roomId !== data.id) return message;
          return {
            ...message,
            lastRead: data.lastMessage?.id,
          };
        });
      } else {
        newMessages = [
          ...messages,
          {
            roomId: data.id,
            lastRead: data.lastMessage?.id,
            lastMessage: data.lastMessage,
            userId: data.members?.length === 2 ? this.otherMemberUserId(data.members) : undefined,
          },
        ];
      }
      this.userIdMessages.next(newMessages);

      if (this.chatRooms.value.find(({ id }) => id === data.id)) {
        this.chatRooms.next(
          (this.chatRooms.value ?? []).map(room => {
            if (room.id === data.id) {
              return {
                ...(room ?? {}),
                unread: false,
              };
            }
            return room;
          })
        );
      }
    }
  }

  getChatRoomName(yourId: number, members: any) {
    const otherMemberNames = [];

    for (const member of members) {
      const memberId = member.user.id;
      if (memberId !== yourId) {
        const firstName = member.user.firstName;
        const lastName = member.user.lastName;
        otherMemberNames.push(`${firstName} ${lastName}`);

        if (otherMemberNames.length >= 3) {
          break; // Stop adding names after the first 3
        }
      }
    }
    return otherMemberNames.join(", ") ?? "";
  }

  getUserMessages(userIds: number[]) {
    const requests = userIds.map(userId => {
      const data = this.userIdMessages.value.find((va: any) => va.userId === userId);
      if (data) {
        return of(data).pipe(
          switchMap((val: any) =>
            this.getMessages(1, 1, val.roomId).pipe(
              map(va => ({
                message: "success",
                result: {
                  lastMessage: va.result.items?.[0] ?? undefined,
                },
              }))
            )
          ),
          map(response => {
            return {
              ...data,
              lastMessage: response.result.lastMessage ?? undefined,
            };
          })
        );
      } else {
        return this.createChatRoom("", null, [userId]).pipe(
          switchMap(val =>
            this.getMessages(1, 1, val.result.id).pipe(
              map(va => ({
                message: "success",
                result: {
                  members: val.result.members,
                  id: val.result.id,
                  lastMessage: va.result.items?.[0] ?? undefined,
                },
              }))
            )
          ),
          map(response => {
            if (response.message === "success" && (response.result.members ?? []).length === 2) {
              return {
                userId: this.otherMemberUserId(response.result.members),
                roomId: response.result.id,
                lastMessage: response.result.lastMessage ?? undefined,
              };
            }
            return null;
          })
        );
      }
    });

    return forkJoin(requests)
      .pipe(map(results => results.filter(result => result !== null)))
      .subscribe(val => {
        const newMessages: UserChat[] = [...(this.userIdMessages.value ?? [])];
        val?.map(va => {
          const prior = this.userIdMessages.value?.find(v => v.userId === va?.userId);
          if (va) {
            if (prior) {
              if (
                !prior.lastMessage?.createdAt ||
                new Date(va.lastMessage.createdAt) > new Date(prior.lastMessage?.createdAt)
              ) {
                prior.lastMessage = va.lastMessage;
              }
            } else newMessages.push(va);
          }
        });
        if (newMessages?.length) this.userIdMessages.next(newMessages);
      });
  }

  addMessage(val: Chat) {
    if (val.roomId) {
      let activeRoomId = undefined;
      const initialUrl = this.locationService.path(false);
      const match = initialUrl.match(/^\/messages\/(\d+)/);
      if (match) activeRoomId = +match[1];

      const found = this.userIdMessages.value.find(a => a.roomId === val.roomId);
      if (found) {
        this.userIdMessages.next(
          this.userIdMessages.value.map(message => {
            if (message.roomId !== val.roomId) return message;
            return {
              ...message,
              lastMessage: val,
              lastRead: message.roomId === activeRoomId ? val.id : message.lastRead,
            };
          })
        );
      } else {
        this.getChatRoom(val.roomId)
          .pipe(takeUntilDestroyed(this.destroyRef), first())
          .subscribe(va => {
            if (va.message === "success") {
              if (va.result.members?.length === 2)
                this.userIdMessages.next([
                  {
                    userId: this.otherMemberUserId(va.result.members),
                    roomId: va.result.id,
                    lastMessage: val,
                    lastRead: va.result.id === activeRoomId ? val.id : undefined,
                  },
                  ...(this.userIdMessages.value ?? []),
                ]);
              else
                this.userIdMessages.next([
                  {
                    userId: undefined,
                    roomId: va.result.id,
                    lastMessage: val,
                    lastRead: va.result.id === activeRoomId ? val.id : undefined,
                  },
                  ...(this.userIdMessages.value ?? []),
                ]);
            }
          });
      }
      const foundChatRoom = this.chatRooms.value.find(a => a.id === val.roomId);
      if (foundChatRoom) {
        this.chatRooms.next(
          this.chatRooms.value.map(chatRoom => {
            if (chatRoom.id !== val.roomId) return chatRoom;
            return {
              ...chatRoom,
              lastMessage: val,
              unread: chatRoom.id !== activeRoomId,
              chats: [val, ...(chatRoom.chats ?? [])],
            };
          })
        );
      } else {
        this.getChatRoom(val.roomId)
          .pipe(takeUntilDestroyed(this.destroyRef), first())
          .subscribe(va => {
            if (va.message === "success") {
              this.chatRooms.next([
                {
                  ...(va.result ?? {}),
                  lastMessage: val,
                  unread: va.result.id !== activeRoomId,
                  chats: [val],
                },
                ...(this.chatRooms.value ?? []),
              ]);
            }
          });
      }
    }
  }

  otherMemberUserId = (members: ChatMember[]) => {
    if (members[0].userId !== this.user?.id) return members[0].userId;
    return members[1].userId;
  };

  changeChatRoomMessage(chatRoomId: number, data: any) {
    this.chatRooms.next(
      this.chatRooms.value.map(chatRoom => {
        if (chatRoom.id !== chatRoomId) return chatRoom;
        return {
          ...(chatRoom ?? {}),
          ...(data ?? {}),
        };
      })
    );
  }

  getChatRoomMessage(chatRoomId: number) {
    const found = this.chatRooms.value.find(a => a.id === chatRoomId);
    if (found) {
      this.changeChatRoomMessage(chatRoomId, { loading: true });
      this.getMessages(found.pageNum ?? 1, found.pageSize ?? 30, chatRoomId)
        .pipe(takeUntilDestroyed(this.destroyRef), first())
        .subscribe({
          error: () => this.changeChatRoomMessage(chatRoomId, { loading: false }),
          next: val => {
            this.changeChatRoomMessage(chatRoomId, { loading: false });
            if (val.message === "success") {
              const chats = [
                ...(found.chats ?? []),
                ...(val.result.items ?? []).filter(({ id }) =>
                  (found.chats ?? []).every(chat => chat.id !== id)
                ),
              ];
              this.changeChatRoomMessage(chatRoomId, {
                chats,
                total: val.result.meta.totalItems,
                pageNum: 1,
                pageSize: 30,
              });
              this.changeChatRoom({
                type: "read",
                data: { id: chatRoomId, lastMessage: chats[0] },
              });
            }
          },
        });
    }
  }

  getMoreChatRoomMessages(chatRoomId: number) {
    const found = this.chatRooms.value.find(a => a.id === chatRoomId);
    if (!found?.loading && (found?.pageNum ?? 1) * (found?.pageSize ?? 30) < (found?.total ?? 0)) {
      if (found?.pageNum) found.pageNum++;
      this.changeChatRoomMessage(chatRoomId, {
        loading: true,
      });
      this.getMessages(found?.pageNum ?? 1, found?.pageSize ?? 30, chatRoomId)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          error: () => {
            this.changeChatRoomMessage(chatRoomId, {
              loading: false,
            });
          },
          next: val => {
            // found.moreLoading = false;
            if (val.message === "success") {
              const chats = [
                ...(found?.chats ?? []),
                ...(val.result.items ?? []).filter(({ id }) =>
                  (found?.chats ?? []).every(chat => chat.id !== id)
                ),
              ];
              this.changeChatRoomMessage(chatRoomId, {
                chats,
                total: val.result.meta.totalItems,
                pageNum: val.result.meta.currentPage,
                loading: false,
              });
            }
          },
        });
    }
  }

  addChatRoomMessage(chatRoomId: number, val: Chat) {
    const found = this.chatRooms.value.find(a => a.id === chatRoomId);
    if (found) {
      const chats = [val, ...(found.chats ?? [])];
      this.changeChatRoomMessage(chatRoomId, {
        chats,
        total: (found?.total ?? 0) + 1,
        lastMessage: val,
      });
    }
  }

  addChatRoom(chatRoom: ChatRoom) {
    this.chatRooms.next([chatRoom, ...(this.chatRooms.value ?? [])]);
  }

  // API CALL

  sendMessage(message: string, roomId: number, content: any) {
    this.socket.emit("newMessage", { message, roomId, content: content ?? null });
  }

  apiSendMessage(userId: number, message: string, roomId: number, content?: any) {
    // this.socket.emit("newMessage", { message, roomId, content: content ?? null });
    return this.httpService.post<BackendResponse<Chat>>(`${this.url}/chat/messages`, {
      userId,
      message,
      roomId,
      content,
      sentAt: new Date(),
    });
  }

  listenMessages() {
    return this.socket.fromEvent<Chat>("onMessage");
  }

  listenNewMessages() {
    return this.socket.fromEvent<Chat>("onNewMessage");
  }

  leaveRoom(roomId: number) {
    this.socket.emit("leaveRoom", roomId);
  }

  joinRoom(roomId: number) {
    this.socket.emit("joinRoom", roomId);
  }

  createChatRoom(
    name: string,
    content: string | null,
    users: (number | string)[],
    companyId?: number
  ) {
    return this.httpService.post<BackendResponse<ChatRoom>>(`${this.url}/chat/rooms`, {
      name,
      content,
      users,
      companyId: companyId,
    });
  }
  editChatRoom(name: string, roomId: number) {
    return this.httpService.put<BackendResponse<ChatRoom>>(`${this.url}/chat/rooms/${roomId}`, {
      name,
    });
  }
  deleteChatRoom(roomId: number) {
    return this.httpService.delete<BackendResponse<ChatRoom>>(`${this.url}/chat/rooms/${roomId}`);
  }
  getChatRoomMembers(roomId: number) {
    return this.httpService.get<BackendResponse<ChatMember[]>>(
      `${this.url}/chat/members/${roomId}`
    );
  }
  addMember(userId: number, roomId: number) {
    return this.httpService.post<MetaCountBackendResponse<ChatRoom>>(`${this.url}/chat/members`, {
      userId,
      roomId,
    });
  }
  removeMember(memberId: number) {
    return this.httpService.delete<MetaCountBackendResponse<ChatRoom>>(
      `${this.url}/chat/members/${memberId}`
    );
  }
  getChatRooms(pageNum: number, pageSize: number) {
    return this.httpService.get<MetaCountBackendResponse<ChatRoom>>(
      `${this.url}/chat/rooms?page=${pageNum}&limit=${pageSize}`
    );
  }
  getChatRoom(roomId: number) {
    return this.httpService.get<BackendResponse<ChatRoom>>(`${this.url}/chat/rooms/${roomId}`);
    // .pipe(delay(1000));
  }
  getMessages(pageNum: number, pageSize: number, roomId: number) {
    return this.httpService
      .get<MetaCountBackendResponse<Chat>>(
        `${this.url}/chat/messages/${roomId}?page=${pageNum}&limit=${pageSize}`
      )
      .pipe(delay(500));
  }
  getUserByEmail(email: string) {
    return this.httpService.post<BackendResponse<User>>(`${this.url}/users/email`, { email });
  }
}
